<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->

    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body>
                <b-row>
                 
                  <b-col
                    cols="12"
                    lg="3"
                  >
                    <div class="mt-2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label="  المستودع"
                          label-for="payment-method"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            v-model="Form.warehouse_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="WarehouseOption"
                            label="name"
                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                  
                  <b-col
                    cols="12"
                    lg="3"
                  >
                    <div class="mt-2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label="  المادة"
                          label-for="payment-method"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            v-model="Form.item_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="ItemData"

                            label="name"
                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                            @input="getDetails(Form.item_id,Form.warehouse_id)"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                  
                
                </b-row>

              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              
              <!-- Items Section -->
              <b-card-body >
                <div
                  ref="form"
                  class="repeater-form"
                >
             
                        <b-row class="">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="12"
                          >
                            <label>
                              حركات الدخول
                            </label>
                          </b-col>
                        </b-row>
                   
                      <b-table
        ref="refFamiliesListTable"
        :items="Form.in_transactions"
        responsive
        :fields="tableColumns"
        primary-key="id"
    
      >
        <!-- Column: editfield -->

     
      </b-table>
                
                </div>
           
              </b-card-body>
            </b-card>
              <!-- Invoice Description: Total -->

              <!-- Spacer -->
              <hr class="invoice-spacing">
 <b-card>
              <b-card-body >
                <div
                  ref="form"
                  class="repeater-form"
              
                >
             
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="12"
                          >
                            <label>
                              حركات الخروح
                            </label>
                          </b-col>
                        </b-row>
                      
                        <b-table
        ref="refFamiliesListTable"
        :items="Form.out_transactions"
        responsive
        :fields="tableColumns"
        primary-key="id"
    
      >
        <!-- Column: editfield -->

     
      </b-table>
                 
                </div>
           
              </b-card-body>
            <!-- Note -->
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
    </b-row>
    <b-row />

    <!-- <invoice-sidebar-send-invoice />
        <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,

  BForm,
  BFormGroup,
  BFormInput,

  BFormInvalidFeedback,
BTable,
  VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import financeStoreModule from './financeStoreModule.js'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    required,
    ValidationProvider,
    ValidationObserver,
    financeStoreModule,
    BFormInvalidFeedback,
    BCard,
    BCardBody,
    BButton,

    BForm,
    BFormGroup,
    BFormInput,

    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    vSelect,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'invoiceData.items': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
  },
  setup() {
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })

    const Form = ref({
      type_id: '',
      from_party_id: null,
      to_party_id: null,
      user_material_order_id: 1,
      request_by_user: 1,
      logistic_officer_signature: null,
      project_manager_signature: null,
      donor_id: 1,
      warehouse_id: 1,
      out_put_id: 1,
      out_come_id: 1,
      item_id: null,
      sub_item_id: null,
      cost_center: null,
      department_id: '',
      ppa_number: null,
      notes: '',
      in_transactions: [
      
      ],
      out_transactions: [
      
      ],
    })
    const invoiceData = ref(null)
    const accountList = ref([])
    const SubAccountList = ref([])

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: '',
    }

    const itemsOptions = ref([
      {
        item_id: '',
        quantity: null,

        unit_id: '',
        price: null,
        currency_id: '',
      },

    ])
    const addNewItemInItemForm = () => {
      Form.value.details.push(
        {
          item_id: '',
          quantity: null,

          unit_id: '',
          price: null,
          currency_id: '',

        },
      )
    }
    const removeItem = index => {
      Form.value.details.splice(index, 1)
    }
    const ItemData = ref([])
    const optionCurrency = ref([])
    store.dispatch('app-finance/GetCurrency').then(response => {
      //console.log('ubitsOption,', optionCurrency)
      optionCurrency.value = response
    })
    const ubitsOption = ref([])
    store.dispatch('app-finance/GetUnits').then(response => {
      //console.log('ubitsOption,', ubitsOption)
      ubitsOption.value = response
    })
    store.dispatch('app-finance/GetItem').then(response => {
      //console.log(response)
      ItemData.value = response
    })
    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
      invoiceData.value.items[index].description = description
    }
    const donorOption = ref([])
    const WarehouseOption = ref([])
    store.dispatch('app-finance/GetDonor').then(response => {
      //console.log(response)
      donorOption.value = response
    })
    store.dispatch('app-finance/GetWareHouse').then(response => {
      //console.log(response)
      WarehouseOption.value = response
    })
    const outComeOption = ref([])
    const outPutOption = ref([])

    const getDetails = (itemId, WarehouseId) => {
      SubAccountList.value = []
      Form.value.in_transactions = []
      Form.value.out_transactions = []
      store.dispatch('app-finance/getDetails', { itemId,WarehouseId }).then(response => {
        //console.log('ubitsOption,', response)

        response.in_transactions.forEach(el => {
          Form.value.in_transactions.push({
            item: el.item,
            price: el.price,
          quantity: el.quantity,
          currency_id:(el.currency )?el.currency.id : null,
          unit: el.unit,
          })
        })
        response.out_transactions.forEach(el => {
          Form.value.out_transactions.push({
           item: el.item,
            price: el.price,
          quantity: el.quantity,
          currency_id:(el.currency )?el.currency.id : null,
          unit: el.unit,
          })
        })
      })
    }
    const departmentOptions = ref([])
    store.dispatch('app-finance/GetDepartment').then(response => {
      //console.log(response)
      departmentOptions.value = response.data
    })
    const partOption = ref([])

    const getPartyType = id => {
      partOption.value = []
      store.dispatch('app-finance/getPartyType', { id }).then(response => {
        //console.log('ubitsOption,', response)
        if (id == 1) {
          response.data.forEach(el => {
            partOption.value.push({
              id: el.id,
              name: el.details.first_name,
            })
          })
        } else {
          response.data.forEach(el => {
            partOption.value.push({
              id: el.id,
              name: el.details.name,
            })
          })
        }
      })
    }

    const warehouseTRType = [
      {
        value: 1,
        label: 'شراء',
      },
      {
        value: 2,
        label: 'نقل',
      },
      {
        value: 3,
        label: 'مرتجع',
      },
      {
        value: 4,
        label: 'تلف',
      },
      {
        value: 5,
        label: 'توزيع',
      },
    ]
    const partType = ref('')
    const partTypeOption = [
      {
        value: 1,
        label: 'مستخدم',
      },
      {
        value: 2,
        label: 'مستودع',
      },
      {
        value: 3,
        label: 'مورد',
      },
    ]
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const onSubmit = () => {
      //console.log(Form)

      store.dispatch('app-finance/createTransaction', Form.value).then(response => {
        Form.value.type_id = '',
        Form.value.from_party_id = '',
        Form.value.to_party_id = '',
        Form.value.notes = '',
        Form.value.details = [{
          item_id: '',
          quantity: 1,
          notes: '',
          unit_id: '',
        }]
        Form.value.donor_id = '',
        Form.value.warehouse_id = '',
        Form.value.out_put_id = '',
        Form.value.out_come_id = '',
        Form.value.item_id = '',
        Form.value.cost_center = null,
        Form.value.sub_item_id = '',
        Form.value.department_id = '',
        Form.value.ppa_number = '',
        resetForm()
        Vue.swal({
          title: '',
          text: 'تمت أضافة الطلب بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }
    const tableColumns = [

    {
      key: 'item.name',
      label: '  المادة',

      sortable: true,
    },
    {
      key: 'quantity',
      label: ' الكمية ',

      sortable: true,
    },
    
    {
      key: 'unit.name',
      label: ' الواحدة ',

      sortable: true,
    },
    {
      key: 'currency_id',
      label: ' العملة ',

      sortable: true,
    },
    {
      key: 'price',
      label: ' السعر ',

      sortable: true,
    },

 
    // { key: 'status', sortable: true },
  
  ]
    return {
      departmentOptions,
         tableColumns,
      Form,
      refFormObserver,
      getValidationState,
      onSubmit,
      resetForm,
      addNewItemInItemForm,
      removeItem,
      ItemData,
      optionCurrency,
      ubitsOption,
      accountList,
      getDetails,
      SubAccountList,
      WarehouseOption,
      donorOption,
      outComeOption,
      outPutOption,
      getPartyType,
      partOption,
      partType,
      partTypeOption,
      invoiceData,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      warehouseTRType,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
button.btn.btn-outline-primary {
    margin: 1.375rem !important;
}
.d-lg-none {
    display: block !important;
}
.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
button.btn.me-20.btn-outline-danger {
  margin-right: 17px;
}
</style>
